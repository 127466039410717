'use client';

import { useLocation } from '@hooks/useLocation';
import { cx } from 'cva';
import Link from 'next/link';
import { useMemo } from 'react';
import { applicationInstancePath } from '@utils/routes';
import { usePathname } from 'next/navigation';
import { getParams } from '@/app/utils/url';
import { URLS_WITH_SIDE_NAV } from '@/components/common/const';
import { useInView } from 'react-intersection-observer';
import { useUserSettings } from '@/hooks/useUserSettings';

export function PrimaryNavigation() {
  const { data: settings } = useUserSettings();
  const isRestrictedModeEnabled = settings?.sign_up.mode === 'restricted';

  const { ref: stickyDetectorRef, inView: isNavUnStuck } = useInView({
    initialInView: true,
  });
  const isNavStuck = !isNavUnStuck;

  const { ref: leftNavInViewRef, inView: isLeftUnFaded } = useInView({
    initialInView: true,
  });
  const isShowingLeftNavFade = !isLeftUnFaded;

  const { ref: rightNavInViewRef, inView: isRightUnFaded } = useInView({
    initialInView: true,
  });
  const isShowingRightNavFade = !isRightUnFaded;

  return (
    <div
      className={cx(
        'sticky top-0 z-10',
        'before:absolute before:inset-x-0 before:bottom-0 before:h-px before:bg-gray-300 before:opacity-0',
        {
          'bg-gray-100 before:opacity-100 dark:border-white/5': isNavStuck,
          'border-transparent': !isNavStuck,
        },
      )}
    >
      <div
        ref={stickyDetectorRef}
        className='absolute inset-x-0 -top-0.5 h-px pointer-events-none'
      />

      <div
        className={cx(
          'no-scrollbar relative flex justify-between overflow-x-auto px-4 md:px-5 ',
          {
            '-top-px': !isNavStuck,
          },
        )}
      >
        <nav className='relative isolate -ml-3.5 flex shrink-0'>
          <div className='absolute inset-y-0 left-0' ref={leftNavInViewRef} />
          <PrimaryNavigationItem isNavStuck={isNavStuck} href='/'>
            Overview
          </PrimaryNavigationItem>
          <PrimaryNavigationItem isNavStuck={isNavStuck} href='/users'>
            Users
          </PrimaryNavigationItem>
          <PrimaryNavigationItem isNavStuck={isNavStuck} href='/organizations'>
            Organizations
          </PrimaryNavigationItem>

          {isRestrictedModeEnabled && (
            <PrimaryNavigationItem isNavStuck={isNavStuck} href='/invitations'>
              Invitations
            </PrimaryNavigationItem>
          )}

          <PrimaryNavigationItem
            isNavStuck={isNavStuck}
            href='/user-authentication/email-phone-username'
          >
            Configure
          </PrimaryNavigationItem>

          <div className='absolute inset-y-0 right-0' ref={rightNavInViewRef} />
        </nav>
      </div>

      <div
        className={cx(
          'pointer-events-none absolute inset-y-0 left-0 w-16 bg-gradient-to-r opacity-0 transition-opacity duration-200',
          {
            'from-gray-100': isNavStuck,
            'from-white': !isNavStuck,
            'opacity-0': !isShowingLeftNavFade,
            'opacity-100': isShowingLeftNavFade,
          },
        )}
      />

      <div
        className={cx(
          'pointer-events-none absolute inset-y-0 right-0 w-16 bg-gradient-to-l transition-opacity duration-200',
          {
            'from-gray-100': isNavStuck,
            'from-white': !isNavStuck,
            'opacity-0': !isShowingRightNavFade,
            'opacity-100': isShowingRightNavFade,
          },
        )}
      />
    </div>
  );
}

export function PrimaryNavigationItem({
  href,
  children,
  isNavStuck,
  onClick,
}: {
  children: string;
  href: string;
  isNavStuck?: boolean;
  onClick?: () => void;
}) {
  const { applicationId, instanceId } = useLocation();
  const pathname = usePathname();
  const { rest } = getParams(pathname ?? '');

  const isActive = useMemo(() => {
    // Check for home route
    if (href === '/') {
      return rest === '';
    }

    // The Configure tab is active if the current route matches any of the
    // paths defined in URLS_WITH_SIDE_NAV
    if (children === 'Configure') {
      return URLS_WITH_SIDE_NAV.some(url => pathname?.includes(url));
    }

    // This prevents conflicts between main nav items and Configure sub-items
    return (
      pathname?.includes(href) &&
      !URLS_WITH_SIDE_NAV.some(url => pathname?.includes(url))
    );
  }, [rest, href, children]);

  return (
    <Link
      href={`${applicationInstancePath({
        applicationId: String(applicationId),
        instanceId: String(instanceId),
      })}${href}`}
      className={cx(
        'relative shrink-0 p-3.5 font-book transition',
        'after:absolute after:-inset-x-0.5 after:inset-y-2 after:rounded-lg after:border-2 after:border-legacy-blue-500 after:opacity-0',
        'focus-visible:after:opacity-100',
        {
          'text-gray-1100 hover:text-gray-1200': !isActive,
          'text-gray-1200': isActive,
        },
      )}
      onClick={onClick}
    >
      {children}

      {isActive && (
        <div
          className={cx('absolute inset-x-3.5 h-px bg-gray-1200', {
            'bottom-0': isNavStuck,
            'top-0': !isNavStuck,
          })}
        />
      )}
    </Link>
  );
}
